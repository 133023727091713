<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card title="Bank Account" icon="mdi-bank">
          <v-sheet>
            <v-toolbar-items class="d-flex align-center">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
              />
              <v-spacer />
              <v-btn color="green" elevation="2" @click="handleDialog">
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-toolbar-items>
          </v-sheet>

          <v-row>
            <v-col cols="12">
              <TableCRUD
                :headers="headers"
                :items="data"
                :search="search"
                @edititem="handleEdit"
                @deleteitem="handleDelete"
              />
            </v-col>
          </v-row>

          <BankAccCRUD
            :dialog.sync="dialog"
            :myObj="obj"
            @handleData="handleAcc"
          />

          <DeleteDialog
            :dialogDelete.sync="deleteDialog"
            :deleteObj="obj"
            @handleDelete="handleDeleteConfirm"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  bankAccountList,
  bankAccountUpsert,
  bankAccountDel,
} from "@/api/financial";

export default {
  name: "bankAccount",
  components: {
    TableCRUD: () => import("@/components/tableCRUD"),
    DeleteDialog: () => import("@/components/deleteDialog"),
    BankAccCRUD: () => import("@/components/financial/bankAccCRUD"),
    PageNavigation: () => import("@/components/pageNavigation"),
  },
  data() {
    return {
      search: "",
      dialog: false,
      deleteDialog: false,
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.bank") },
      ],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          width: "100",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.bankName"),
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.accountName"),
          value: "account",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.bankNo"),
          value: "no",
          width: "140",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.addr"),
          value: "branch",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Country"),
          value: "country",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.SwiftCode"),
          value: "swift_code",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
          width: "80",
          class: "primary--text",
        },
      ],
      searchForm: {
        skip: 0,
        limit: 100,
        key: "",
      },
      obj: {
        id: "",
        name: "",
        account: "",
        no: "",
        branch: "",
        swift_code: "",
        note: "",
        country: "",
      },
      data: [],
    };
  },
  methods: {
    getData() {
      bankAccountList(this.searchForm).then((res) => {
        this.data = [...res.items];

        this.data.forEach((p, i) => (p.itemNo = i + 1));
      });
    },
    handleDialog() {
      this.dialog = true;

      this.obj = {
        id: "",
        name: "",
        account: "",
        no: "",
        branch: "",
        swift_code: "",
        note: "",
        country: "",
      };
    },
    handleAcc(obj) {
      this.dialog = false;

      bankAccountUpsert(obj)
        .then(() => this.getData())
        .catch((err) => console.log("Bank CRUD Error", err));
    },
    handleEdit(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.obj = obj;
      }
    },
    handleDelete(obj) {
      this.deleteDialog = true;
      this.obj = obj;
    },
    handleDeleteConfirm(obj) {
      this.deleteDialog = false;

      this.data.splice(this.data.indexOf(obj), 1);

      bankAccountDel(obj.id).catch((err) =>
        console.log("Bank Delete Error", err)
      );
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
